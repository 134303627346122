import axios from 'axios';
import { method } from 'lodash';

// #region ======== auth =========

// Admin Login
const syncUrl = process.env.REACT_APP_SYNC_URL;
async function loginAsync(payload) {
  const config = {
    method: 'post',
    url: 'admin/login',
    data: payload
  };
  return await axios(config);
}

async function addAdminAsync(payload) {
  const config = {
    method: 'post',
    url: 'admin/user',
    data: payload
  };
  return await axios(config);
}

async function updateAdminAsync(payload) {
  const config = {
    method: 'put',
    url: 'admin/user',
    data: payload
  };
  return await axios(config);
}

async function getRoleListAsync() {
  const config = {
    method: 'get',
    url: '/admin/role-list'
  };
  return await axios(config);
}

async function getUserListAsync(payload) {
  const config = {
    method: 'get',
    url: 'admin/user-list',
    data: payload
  };
  return await axios(config);
}

async function deleteAdminAsync(systemUserId) {
  const config = {
    method: 'delete',
    url: `admin/user/${systemUserId}`
  };
  return await axios(config);
}

async function updateSyncAsync(payload) {
  const config = {
    method: 'put',
    url: '/admin/sync',
    data: payload
  };
  return await axios(config);
}

async function getSyncDataAsync() {
  const config = {
    method: 'get',
    url: '/admin/syncData'
  };
  return await axios(config);
}

async function getUpdatedItemsAsync() {
  const config = {
    method: 'get',
    url: `${syncUrl}/admin/updated/items`
  };
  return await axios(config);
}

async function updatedCategoriesAsync(subMenuIds) {
  const config = {
    method: 'post',
    url: `${syncUrl}/admin/sync/categories`,
    data: { subMenuIds }
  };
  return await axios(config);
}

async function updatedMenuItemsAsync(posItemIds) {
  const config = {
    method: 'post',
    url: `${syncUrl}/admin/sync/menuItems`,
    data: { posItemIds }
  };
  return await axios(config);
}

async function updatedCombosAsync(menuItemIds) {
  const config = {
    method: 'post',
    url: `${syncUrl}/admin/sync/combo`,
    data: { menuItemIds }
  };
  return await axios(config);
}

async function updatedModifiersAsync(posItemIds) {
  const config = {
    method: 'post',
    url: `${syncUrl}/admin/sync/modifiers`,
    data: { posItemIds }
  };
  return await axios(config);
}

async function sendAuthenticationMailAsync(systemUserId) {
  const config = {
    method: 'post',
    url: '/admin/send-mail',
    data: { systemUserId }
  };
  return await axios(config);
}

async function resetAdminPasswordAsync(payload) {
  const config = {
    method: 'post',
    url: '/admin/reset/admin/password',
    data: payload
  };
  return await axios(config);
}

async function resetPasswordAsync(payload) {
  const config = {
    method: 'post',
    url: '/admin/reset/password',
    data: payload
  };
  return await axios(config);
}
// #endregion ======== auth =========

// #start banner ======== Banner =========

async function getBannerListAsync() {
  const config = {
    method: 'get',
    url: '/banners/get'
  };
  return await axios(config);
}

async function saveBannerAsync(payload) {
  const config = {
    method: 'post',
    url: '/banners/save',
    data: payload
  };
  return await axios(config);
}

async function deleteBannerAsync(bannerId) {
  const config = {
    method: 'delete',
    url: `/banners/${bannerId}`
  };
  return await axios(config);
}

async function getLookupSubMenuItemsAsync() {
  const config = {
    method: 'get',
    url: '/lookup/submenu/items'
  };
  return await axios(config);
}
// #end banner ======== Banner =========

// #start-order ======== order =========

async function getOrderListAsync(payload) {
  const config = {
    method: 'post',
    url: '/order/list',
    data: payload
  };
  return await axios(config);
}

async function getOrderStatistcsAsync(payload) {
  const config = {
    method: 'post',
    url: '/order/statistics',
    data: payload
  };
  return await axios(config);
}

async function getOrderDetailAsync(orderId) {
  const config = {
    method: 'get',
    url: `/order/details/${orderId}`
  };
  return await axios(config);
}

async function cancelOrderAsync(orderId) {
  const config = {
    method: 'delete',
    url: `/order/cancel-order/${orderId}`
  };
  return await axios(config);
}

async function orderRefundAsync(orderId, payload) {
  const config = {
    method: 'post',
    url: `/order/refund/${orderId}`,
    data: payload
  };
  return await axios(config);
}

async function creditPointsAsync(payload) {
  const config = {
    method: 'post',
    url: '/payment/points',
    data: payload
  };
  return await axios(config);
}

async function getCreditPointsAsync(orderId, payload) {
  const config = {
    method: 'get',
    url: `/payment/points/${orderId}`,
    data: payload
  };
  return await axios(config);
}
async function orderPayRequestAsync(orderId) {
  const config = {
    method: 'get',
    url: `/order/pay-request/${orderId}`
  };
  return await axios(config);
}

async function viewOrderAsync(orderId) {
  const config = {
    method: 'get',
    url: `/order/validJson/${orderId}`
  };
  return await axios(config);
}

async function orderDeliveryRequestAsync(siteId, deliveryId) {
  const config = {
    method: 'get',
    url: `/order/delivery-request/${siteId}/${deliveryId}`
  };
  return await axios(config);
}
// #end-order ======== order =========

// #start-menu & category ======== menu & category =========

async function getCategoryItemsAsync(categoryId) {
  const config = {
    method: 'get',
    url: `/menu/items/category/${categoryId}`,
  };
  return await axios(config);
}

async function getMenuItemsAsync() {
  const config = {
    method: 'get',
    url: '/menu/override/items'
  };
  return await axios(config);
}

async function getCategoriesByPosItemIdAsync(posItemId) {
  const config = {
    method: 'get',
    url: `/menu/web-categories/${posItemId}`
  };
  return await axios(config);
}

async function getComboItemsByMenuItemIdAsync(menuItemId) {
  const config = {
    method: 'get',
    url: `/menu/items/combo/${menuItemId}`
  };
  return await axios(config);
}

async function updateComboIdAsync(comboId, menuItemId) {
  const config = {
    method: 'put',
    url: `/menu/items/update-comboId/${comboId}/${menuItemId}`
  };
  return await axios(config);
}

async function getMenuListAsync(itemType) {
  const config = {
    method: 'get',
    url: '/menu/items',
    params: { type: itemType }
  };
  return await axios(config);
}

async function getSasUrlAsync() {
  const config = {
    method: 'get',
    url: '/file/sas-url/'
  };
  return await axios(config);
}

async function updateMenuImageAsync(posItemId, imagePath) {
  const config = {
    method: 'put',
    url: `/menu/items/menu-image/${posItemId}`,
    data: { imagePath }
  };
  return await axios(config);
}

async function updateComboImageAsync(menuItemId, imagePath) {
  const config = {
    method: 'put',
    url: `/menu/items/combo-image/${menuItemId}`,
    data: { imagePath }
  };
  return await axios(config);
}

async function updateStatusByMenuItemIdAsync(menuItemId, isVisible) {
  const config = {
    method: 'put',
    url: `/menu/combo-items/status/${menuItemId}`,
    data: { isVisible }
  };
  return await axios(config);
}

async function updatePopularItemAsync(menuItemId, isPopular) {
  const config = {
    method: 'put',
    url: `/menu/popular/item/${menuItemId}`,
    data: { isPopular }
  };
  return await axios(config);
}

async function updateSequenceAsync(payload) {
  const config = {
    method: 'put',
    url: '/menu/items/sequence',
    data: payload
  };
  return await axios(config);
}

async function getSubMenuItemsAsync() {
  const config = {
    method: 'get',
    url: '/menu/submenu/items'
  };
  return await axios(config);
}

async function updateSubMenuItemAsync(data) {
  const config = {
    method: 'put',
    url: `/menu/submenu`,
    data: data
  };
  return await axios(config);
}

async function updateMenuAsync(payload) {
  const config = {
    method: 'put',
    url: '/menu/save',
    data: payload
  };
  return await axios(config);
}

async function getBlobImagesAsync(path) {
  const config = {
    method: 'get',
    url: '/file/images',
    params: { path: path }
  };
  return await axios(config);
}

async function updateComboAsync(payload) {
  const config = {
    method: 'put',
    url: '/menu/combo/save',
    data: payload
  };
  return await axios(config);
}

async function updateStatusByPosItemIdAsync(posItemId, isVisible) {
  const config = {
    method: 'put',
    url: `/menu/items/status/${posItemId}`,
    data: { isVisible }
  };
  return await axios(config);
}
// #end-menu & category ======== menu & category =========

// #start-site ======== site =========

async function getSitesAsync() {
  const config = {
    method: 'get',
    url: '/site/list'
  };
  return await axios(config);
}

async function getSitesOptionsAsync() {
  const config = {
    method: 'get',
    url: '/sites'
  };
  return await axios(config);
}

async function getSiteListAsync() {
  const config = {
    method: 'get',
    url: '/admin/user-site'
  };
  return await axios(config);
}

async function getReportListAsync() {
  const config = {
    method: 'get',
    url: '/lookup/reports'
  };
  return await axios(config);
}

async function getSitesByIdAsync(systemUserId) {
  const config = {
    method: 'get',
    url: `/admin/user-sites/${systemUserId}`
  };
  return await axios(config);
}

async function setSiteEnabledStatusAsync(siteId, isEnabled) {
  const config = {
    method: 'put',
    url: `/site/updateEnabled/${siteId}/${isEnabled}`,
    data: { isEnabled }
  };
  return await axios(config);
}

async function updateSiteAsync(siteId, payload) {
  const config = {
    method: 'put',
    url: `/site/updateSite/${siteId}`,
    data: payload
  };
  return await axios(config);
}

async function getSiteDetailsAsync(siteId) {
  const config = {
    method: 'get',
    url: `/site/${siteId}`
  };
  return await axios(config);
}

async function updateSiteTimeAsync(siteId, payload) {
  const config = {
    method: 'put',
    url: `/site/${siteId}`,
    data: payload
  };
  return await axios(config);
}

async function getSiteHoursAsync(siteId) {
  const config = {
    method: 'get',
    url: `/site/hours/${siteId}`
  };
  return await axios(config);
}

async function verifyUberDeliveryAsync(siteId, uberSiteId) {
  const config = {
    method: 'post',
    url: '/site/verify/uber-delivery',
    data: { siteId, uberSiteId }
  };
  return await axios(config);
}

async function verifyDoorDashDeliveryAsync(siteId, doorDashSiteId) {
  const config = {
    method: 'post',
    url: '/site/verify/doordash-delivery',
    data: { siteId, doorDashSiteId }
  };
  return await axios(config);
}

async function verifyTyroAsync(siteId, tyroLocationId) {
  const config = {
    method: 'post',
    url: '/site/verify-payment',
    data: { siteId, tyroLocationId }
  };
  return await axios(config);
}

async function getVerifyDetailsAsync(siteId) {
  const config = {
    method: 'get',
    url: `/site/verify/details/${siteId}`
  };
  return await axios(config);
}

async function saveVerifyDetailAsync(verifyDetail, siteId) {
  const config = {
    method: 'post',
    url: `/site/save/${siteId}`,
    data: verifyDetail
  };
  return await axios(config);
}
// #end-site ======== site =========

// #start-feedback ======== feedback =========

async function getFeedbackSummaryAsync(payload) {
  const config = {
    method: 'post',
    url: '/feedback/summary',
    data: payload
  };
  return await axios(config);
}

async function getFeedbackListAsync(payload) {
  const config = {
    method: 'post',
    url: '/feedback/list',
    data: payload
  };
  return await axios(config);
}

// #end-feedback ======== feedback =========

// #start-siterule & rule ======== siterule & rule =========

async function getRulesAsync() {
  const config = {
    method: 'get',
    url: '/rule/list'
  };
  return await axios(config);
}
async function getSiteRulesAsync(conditionId) {
  const config = {
    method: 'get',
    url: `/rule/site/override/${conditionId}`
  };
  return await axios(config);
}

async function deleteSiteRuleAsync(siteId) {
  const config = {
    method: 'delete',
    url: `/rule/site/${siteId}`
  };
  return await axios(config);
}
async function deleteRuleAsync(conditionId) {
  const config = {
    method: 'delete',
    url: `/rule/${conditionId}`
  };
  return await axios(config);
}

async function saveSiteRuleAsync(siteRule) {
  const config = {
    method: 'post',
    url: '/rule/site',
    data: siteRule
  };
  return await axios(config);
}

async function saveRuleAsync(payload) {
  const config = {
    method: 'post',
    url: '/rule/save',
    data: payload
  };
  return await axios(config);
}

async function getRuleAsync(conditionId) {
  const config = {
    method: 'get',
    url: `/rule/${conditionId}`
  };
  return await axios(config);
}

async function getRuleTypesAsync() {
  const config = {
    method: 'get',
    url: '/rule/types'
  };
  return await axios(config);
}

// #end-siterule & rule ======== siterule & rule =========

// Disabled PosItem
async function getDisabledPosItemListAsync(itemType) {
  const config = {
    method: 'get',
    url: '/admin/disabled/items',
    params: { itemType: itemType }
  };
  return await axios(config);
}

// delete Disabled combo
async function enableComboAsync(payload) {
  const config = {
    method: 'put',
    url: '/admin/enable/combo',
    data: payload
  };
  return await axios(config);
}

// delete Disabled PosItem
async function enablePosItemAsync(payload) {
  const config = {
    method: 'delete',
    url: '/admin/enable/item',
    data: payload
  };
  return await axios(config);
}

// add Disable PosItem
async function disablePosItemAsync(payload) {
  const config = {
    method: 'post',
    url: '/admin/disable/item',
    data: payload
  };
  return await axios(config);
}

async function disableComboAsync(payload) {
  const config = {
    method: 'put',
    url: '/admin/disable/combo',
    data: payload
  };
  return await axios(config);
}

async function getPosItemOptionListAsync() {
  const config = {
    method: 'get',
    url: '/admin/positem/options'
  };
  return await axios(config);
}

async function getComboOptionListAsync() {
  const config = {
    method: 'get',
    url: '/admin/combo/options'
  };
  return await axios(config);
}


// #start-modifier items ======== modifier-items =========
async function getModifierItemsAsync() {
  const config = {
    method: 'get',
    url: '/menu/modifiers'
  };
  return await axios(config);
}

async function getComboOptionsAsync() {
  const config = {
    method: 'get',
    url: '/admin/combo/options'
  };
  return await axios(config);
}

async function upadateModifierItemImageAsync(posItemId, imagePath) {
  const config = {
    method: 'put',
    url: `/menu/modifier-image/${posItemId}`,
    data: { imagePath }
  };
  return await axios(config);
}

async function upadateModifierItemSetAsync(posItemId, payload) {
  const config = {
    method: 'put',
    url: `/menu/modifier-set/${posItemId}`,
    data: payload
  };
  return await axios(config);
}

async function deleteModifierItem(posItemId, payload) {
  const config = {
    method: 'delete',
    url: `/menu/modifier/item/${posItemId}`,
    data: payload
  };
  return await axios(config);
}

async function deleteCategoryItemAsync(menuItemId, subMenuId) {
  const config = {
    method: 'delete',
    url: `/menu/category/item/${menuItemId}/${subMenuId}`,
  };
  return await axios(config);
}

async function addCategoryItemAsync(menuItemId, subMenuId) {
  const config = {
    method: 'post',
    url: `/menu/category-item/${menuItemId}/${subMenuId}`,
  };
  return await axios(config);
}

// #start-ScreenSaver ======== ScreenSaver ========

async function getScreenSaverListAsync() {
  const config = {
    method: 'get',
    url: '/banners/kiosk'
  };
  return await axios(config);
}

async function saveScreenSaverAsync(payload) {
  const config = {
    method: 'post',
    url: '/banners/kiosk',
    data: payload
  };
  return await axios(config);
}

async function isScreenSaverDeletedAsync(screenSaverId, isDeleted) {
  const config = {
    method: 'put',
    url: `/banners/kiosk/${screenSaverId}/${isDeleted}`
  };
  return await axios(config);
}

// #start-kiosk ======== kiosk ========

async function getSiteKiosksAsync() {
  const config = {
    method: 'get',
    url: '/site/kiosk/list'
  };
  return await axios(config);
}

async function deleteKioskAsync(kioskId) {
  const config = {
    method: 'delete',
    url: `site/kiosk/${kioskId}`
  };
  return await axios(config);
}

async function kioskNotificationAsync(payload) {
  const config = {
    method: 'post',
    url: '/site/kiosk/notification',
    data: payload
  };
  return await axios(config);
}

async function updateKioskStatusAsync(kioskId, isDeleted) {
  const config = {
    method: 'put',
    url: `/site/kiosk/status/${kioskId}`,
    data: { isDeleted }
  };
  return await axios(config);
}

//#start-Notification rule ======== Notification rule ========
async function getAttributesAsync() {
  const config = {
    method: 'get',
    url: '/lookup/attributes'
  };
  return await axios(config);
}

async function saveNotificationRulesAsync(payload) {
  const config = {
    method: 'post',
    url: '/admin/save/notification/rule',
    data: payload
  };
  return await axios(config);
}

async function getNotifyCustomersAsync(notificationRuleId, payload) {
  const config = {
    method: 'post',
    url: `/admin/customers-notify/${notificationRuleId}`,
    data: payload
  };
  return await axios(config);
}

async function saveNotificationMessageAsync(payload) {
  const config = {
    method: 'post',
    url: `/admin/save/notification/message`,
    data: payload
  };
  return await axios(config);
}

async function getFutureNotificationsAsync(notificationRuleId) {
  const config = {
    method: 'get',
    url: `/admin/notification/future/${notificationRuleId}`
  };
  return await axios(config);
}

async function deleteFutureNotificationAsync(messageId) {
  const config = {
    method: 'delete',
    url: `/admin/notification/message/${messageId}`
  };
  return await axios(config);
}

async function getAdhocReport(payload) {
  const config = {
    method: 'post',
    data: payload,
    url: `/sales/reports`
  };
  return await axios(config);
}

async function getNotificationRulesAsync() {
  const config = {
    method: 'get',
    url: `/admin/notification/rules`
  };
  return await axios(config);
}

async function getNotificationRulesHistoryAsync(notificationRuleId) {
  const config = {
    method: 'get',
    url: `/admin/notification/log/${notificationRuleId}`
  };
  return await axios(config);
}

async function getMessageLogAsync(messageId) {
  const config = {
    method: 'get',
    url: `/admin/message/log/${messageId}`
  };
  return await axios(config);
}

async function deleteNotificationRuleAsync(notificationRuleId) {
  const config = {
    method: 'delete',
    url: `/admin/notification/rule/${notificationRuleId}`
  };
  return await axios(config);
}

//Start of POS
async function getEmpListAsync() {
  const config = {
    method: 'get',
    url: '/pos/emp-list'
  };
  return await axios(config);
}

async function deleteEmpAsync(empId) {
  const config = {
    method: 'delete',
    url: `/pos/emp/${empId}`
  };
  return await axios(config);
}

async function addEmpAsync(payload) {
  const config = {
    method: 'post',
    url: '/pos/emp',
    data: payload
  };
  return await axios(config);
}

async function updateEmpAsync(payload) {
  const config = {
    method: 'put',
    url: '/pos/emp',
    data: payload
  };
  return await axios(config);
}

async function getSitePosListAsync() {
  const config = {
    method: 'get',
    url: '/pos/site'
  };
  return await axios(config);
}

async function getPosIdsAsync(siteId, screenType) {
  const config = {
    method: 'post',
    url: `/pos/ids/${siteId}`,
    data: { screenType }
  };
  return await axios(config);
}

async function createSocketPairAsync(payload) {
  const config = {
    method: 'post',
    url: '/pos/socket/pair',
    data: payload
  };
  return await axios(config);
}

async function getSocketPairsAsync(posId) {
  const config = {
    method: 'get',
    url: `/pos/pair/${posId}`
  };
  return await axios(config);
}

async function getReceiptDetailsAsync(orderId) {
  const config = {
    method: 'get',
    url: `/lookup/receipt/${orderId}`
  };
  return await axios(config);
}

async function posNotificationAsync(payload) {
  const config = {
    method: 'post',
    url: '/pos/notification',
    data: payload
  };
  return await axios(config);
}

async function deleteSocketPairAsync(posId) {
  const config = {
    method: 'delete',
    url: `/pos/socket/pair/${posId}`
  };
  return await axios(config);
}

//#start-Device ======== Devices ========

async function getDevicesAsync(deviceType) {
  const config = {
    method: 'get',
    url: '/device/list',
    params: { deviceType: deviceType }
  };
  return await axios(config);
}

async function updateDeviceStatusAsync(id, deviceType, isDeleted, siteId) {
  const config = {
    method: 'put',
    url: `/device/status/${id}/${siteId}`,
    params: { deviceType: deviceType },
    data: { isDeleted }
  };
  return await axios(config);
}

async function sendSyncNotificationAsync(payload) {
  const config = {
    method: 'post',
    url: '/device/sync/notification',
    params: { deviceType: payload.deviceType },
    data: payload
  };
  return await axios(config);
}

async function getGroupItemsAsync() {
  const config = {
    url: '/kds/group-items/list',
    method: 'get',
  }
  return await axios(config);
}

async function getUnassignedGroupItemsAsync() {
  const config = {
    url: '/kds/group-items',
    method: 'get',
  }
  return await axios(config);
}

async function deleteGroupAsync(posId) {
  const config = {
    method: 'delete',
    url: `/kds/group-item/${posId}`
  };
  return await axios(config);
}

async function assignGroupItemsAsync(payload) {
  const config = {
    method: 'post',
    url: `/kds/group/items`,
    data: payload
  };
  return await axios(config);
}


// #start-KDS ======== kds ========
async function getScreenGroupsAsync() {
  const config = {
    method: 'get',
    url: '/kds/screen-groups',
  };
  return await axios(config);
}

async function configureScreenGroupsAsync(data) {
  const config = {
    method: 'post',
    url: '/kds/configure-groups',
    data: data
  };
  return await axios(config);
}

async function updateScreenGroupsAsync(data) {
  const config = {
    method: 'put',
    url: '/kds/update-groups',
    data: data
  };
  return await axios(config);
}

async function getAssignedGroupsBySiteIdAsync(siteId) {
  const config = {
    method: 'get',
    url: `/kds/site-groups/${siteId}`,

  };
  return await axios(config);
}

async function deleteAssignedGroupsByAsync(kitchenScreenId) {
  const config = {
    method: 'delete',
    url: `/kds/groups/${kitchenScreenId}`,

  };
  return await axios(config);
}

async function getUnassignedSuggestiveItemAsync() {
  const config = {
    url: '/menu/suggestive-items/unassigned',
    method: 'get',
  }
  return await axios(config);
}

async function assignedSuggestiveItemAsync(payload) {
  const config = {
    url: '/menu/suggestive-items/save',
    method: 'post',
    data: payload
  }
  return await axios(config);
}

async function deleteSuggestiveItemAsync(posItemId) {
  const config = {
    url: `/menu/suggestive-items/${posItemId}`,
    method: 'delete'
  }
  return await axios(config);
}

async function getAssignedSuggestiveItemAsync() {
  const config = {
    method: 'get',
    url: '/menu/suggestive-items/assigned'
  }
  return await axios(config);
}


export default {
  // auth
  loginAsync,
  deleteAdminAsync,
  addAdminAsync,
  updateAdminAsync,
  getRoleListAsync,
  getUserListAsync,
  getOrderListAsync,
  getOrderStatistcsAsync,
  getSiteListAsync,
  updateSyncAsync,
  getSyncDataAsync,
  orderRefundAsync,
  orderPayRequestAsync,
  orderDeliveryRequestAsync,
  getUpdatedItemsAsync,
  updatedCategoriesAsync,
  updatedMenuItemsAsync,
  updatedCombosAsync,
  updatedModifiersAsync,
  sendAuthenticationMailAsync,
  resetAdminPasswordAsync,
  resetPasswordAsync,
  creditPointsAsync,
  getCreditPointsAsync,
  getReportListAsync,

  // banner
  getBannerListAsync,
  saveBannerAsync,
  deleteBannerAsync,
  getLookupSubMenuItemsAsync,

  // Menu
  getCategoryItemsAsync,
  getMenuItemsAsync,
  getMenuListAsync,
  getSasUrlAsync,
  updateMenuImageAsync,
  updateComboImageAsync,
  updateStatusByMenuItemIdAsync,
  updatePopularItemAsync,
  updateSequenceAsync,
  updateMenuAsync,
  getBlobImagesAsync,
  updateComboAsync,
  getModifierItemsAsync,
  getComboOptionsAsync,
  upadateModifierItemImageAsync,
  upadateModifierItemSetAsync,
  updateStatusByPosItemIdAsync,
  deleteModifierItem,
  getCategoriesByPosItemIdAsync,
  deleteCategoryItemAsync,
  addCategoryItemAsync,
  getComboItemsByMenuItemIdAsync,
  updateComboIdAsync,
  getUnassignedSuggestiveItemAsync,
  assignedSuggestiveItemAsync,
  deleteSuggestiveItemAsync,
  getAssignedSuggestiveItemAsync,

  // Sub Menu
  getSubMenuItemsAsync,
  updateSubMenuItemAsync,

  // Site
  getSitesAsync,
  getSitesByIdAsync,
  setSiteEnabledStatusAsync,
  updateSiteAsync,
  getSitesOptionsAsync,
  getSiteDetailsAsync,
  updateSiteTimeAsync,
  getSiteHoursAsync,
  verifyUberDeliveryAsync,
  verifyDoorDashDeliveryAsync,
  verifyTyroAsync,
  getVerifyDetailsAsync,
  saveVerifyDetailAsync,

  // Order Detail
  getOrderDetailAsync,
  cancelOrderAsync,

  // feedback & review
  getFeedbackSummaryAsync,
  getFeedbackListAsync,

  // Rule
  getRulesAsync,
  deleteRuleAsync,
  saveSiteRuleAsync,
  deleteSiteRuleAsync,
  getSiteRulesAsync,
  saveRuleAsync,
  getRuleAsync,
  getRuleTypesAsync,

  // Disabled PosItem
  getDisabledPosItemListAsync,
  enablePosItemAsync,
  enableComboAsync,
  disablePosItemAsync,
  disableComboAsync,
  getPosItemOptionListAsync,
  getComboOptionListAsync,

  // Screen Saver
  getScreenSaverListAsync,
  saveScreenSaverAsync,
  isScreenSaverDeletedAsync,
  getAdhocReport,

  //kiosk
  getSiteKiosksAsync,
  deleteKioskAsync,
  kioskNotificationAsync,
  updateKioskStatusAsync,

  //Notification rule
  getAttributesAsync,
  saveNotificationRulesAsync,
  getNotifyCustomersAsync,
  saveNotificationMessageAsync,
  getNotificationRulesAsync,
  getNotificationRulesHistoryAsync,
  getMessageLogAsync,
  deleteNotificationRuleAsync,
  getFutureNotificationsAsync,
  deleteFutureNotificationAsync,

  //POS Employee
  getEmpListAsync,
  deleteEmpAsync,
  addEmpAsync,
  updateEmpAsync,

  getSitePosListAsync,
  getPosIdsAsync,
  createSocketPairAsync,
  getSocketPairsAsync,
  getReceiptDetailsAsync,
  posNotificationAsync,
  deleteSocketPairAsync,

  //Devices
  getDevicesAsync,
  updateDeviceStatusAsync,
  sendSyncNotificationAsync,

  //kds
  getUnassignedGroupItemsAsync,
  getGroupItemsAsync,
  deleteGroupAsync,
  assignGroupItemsAsync,
  getScreenGroupsAsync,
  configureScreenGroupsAsync,
  getAssignedGroupsBySiteIdAsync,
  updateScreenGroupsAsync,
  deleteAssignedGroupsByAsync,
  viewOrderAsync
};
